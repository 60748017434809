<template>
    <div>
        <ConfirmDialog></ConfirmDialog>
        <Toast />
        <div>
            <div v-for="product in cartProducts"
                :key="product.id"
                class="products-container">
                <div><img :src="product.image_url" alt="" class="product-image"></div>
                <div class="product-info">
                    <div class="product-content">
                    <p>{{product.name}}</p> 
                    <p>Объем: {{product.size}} л.</p>
                    <p class="product-price">Цена: {{product.price*product.quantity}} грн.</p>
                    </div>
                     <div class="qua-container">
                        <Button label="-" class="p-button-outlined p-button-secondary" @click="decrement(product)"/>
                        <div class="qua-item">{{product.quantity}}</div>
                        <Button label="+" class="p-button-outlined p-button-secondary" @click="increment(product)"/>
                        <div class="product-trash">
                            <Button icon="pi pi-trash" class="p-button-outlined p-button-secondary" @click="confirmDeleteItem(product)" />
                        </div>
                </div>
                
                </div>
               
            </div>
        </div>

        <div class="footer">
            <div class="footer-item"> <b> Сумма заказа: {{orderService.order.total.sum}} грн.</b></div>
            <div class="footer-item"><Button label="Оформить заказ" icon="pi pi-check" class="p-button-secondary" @click="nextPage()"/></div>
        </div>

        

    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import OrderService from '../service/OrderService';

export default {
    data() {
        return {
            cartProducts: null
        }
    },
    orderService: null,

    created() {
		this.orderService = new OrderService();
        this.orderService.order = JSON.parse(JSON.stringify(this.globalOrder));
    },
    async mounted() {
        await this.orderService.getProducts();
        this.cartProducts = this.orderService.products;
    },
    computed: mapState({
        globalOrder: state => state.orders.globalOrder,
    }),
    
    methods: {

        ...mapMutations([
            'setOrder',
            'setCartCount'
        ]),
        async confirmDeleteItem (prod) {
            this.$confirm.require({
                message: 'Вы действительно хотите удалить товар из корзины?',
                header: 'Удалить товар',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    this.orderService.deleteOrderItem(prod.id);
                    this.setCartCount(this.orderService.order.total.quantity);
                    this.$toast.add({severity:'error', summary:'Товар удален', detail: prod.name, life: 1000});
                    await this.orderService.getProducts();
                    this.cartProducts = this.orderService.products;
                    console.log(this.orderService.products)
                },
                reject: () => {
                    this.$toast.add({severity:'info', summary:'Товар не удалён', detail: prod.name, life: 1000});
                }
            });
            
        },
        
        async increment (o) {
            this.orderService.increment(o);
            await this.orderService.getProducts();
            this.cartProducts = this.orderService.products;
            this.setCartCount(this.orderService.order.total.quantity);
        },

        async decrement (o) {
            this.orderService.decrement(o);
            await this.orderService.getProducts();
            this.cartProducts = this.orderService.products;
            this.setCartCount(this.orderService.order.total.quantity);
        },

        nextPage() {
            this.$emit('next-page', {pageIndex: 0});
            this.setOrder(this.orderService.order)
        }
    }
}
</script>

<style scoped>
.product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.qua-container {
    display: flex;
}
.qua-item {
    margin: 10px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    max-width: 91%;
    align-items: center;
    padding: 10px;
    
}
.product-price {
    font-weight: 500;
}
.footer-item {
    text-align: center;
    padding: 5px 0px 0px 20px;
}
.products-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.product-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1 1 80%;
}
.product-content {
    flex: 0 1 50%;
}
.product-trash {
    padding-left: 20px;
}
@media screen and (max-width: 1320px) {
    .footer {
        flex-direction: column;
    }

}

@media screen and (max-width: 700px) {
    .product-info {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 20px;
    }
    .product-content {
        padding-bottom: 10px;
    }

}

</style>